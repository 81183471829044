import React, { useState } from 'react'
import { Alert, AnimatedIcon, ElevatedCard, PageHeadingBlock, Stack, Block, Button, Text } from '@stadion/bright'
import { Box, Flex } from '@chakra-ui/layout'
import { useNavigate, useLocation } from 'react-router'
import PageWrapper from '../../PageWrapper'
import { dashboard } from '../../../routes'



function IncomeConfirm() {
    const navigate = useNavigate()
    const location = useLocation();
    const [ errors, setErrors ] = useState({})
    
    const handleContinueClick = () =>
    {
        navigate(dashboard, {state: { from: location.pathname }})
    }
    
    const errorEntries = Object.values(errors).filter(err => err)
    
    
    return (
        <PageWrapper pageTitle="Income Plan Saved">
            <Block palette="baseTwo" paddingY="4xl">
                <Flex height="100%" flexDir="column">

                    {
                        errorEntries.length > 0 &&
                        <ElevatedCard>
                            <Stack space="xl">
                                <Alert
                                    variant="negative"
                                    heading="A system error has occurred"
                                    content={
                                        errors.generic ||
                                        "Errors occurred during processing"}
                                />
                            </Stack>
                        </ElevatedCard>
                    }

                    <Stack alignX="center">
                        <Box>
                            <AnimatedIcon
                                autoPlay
                                variant="Success"
                            />
                        </Box>

                        <Box>
                            <PageHeadingBlock
                                pageHeading={"Retirement strategy has been saved"}
                                showDivider={false}
                            />
                        </Box>

                        <Box mt={4}>
                            <Text as="p" align="center">
                                You have finished the income calculator! Please return to the dashboard to view a summary of your account.
                            </Text>
                        </Box>

                        <Box mt={8}>
                            <Button onClick={handleContinueClick}>
                                View dashboard
                            </Button>
                        </Box>
                    </Stack>
                </Flex>
            </Block>
        </PageWrapper>
    )
}


export default IncomeConfirm
import React, { useEffect, useState } from 'react'
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, Alert, ElevatedCard, OutlineCard, PageHeadingBlock, PageLoading, QuitAndRestartModal, Radio, RadioGroup, Stack, Block, Button, Link, Adjacent, Text, Table, Thead, Tbody, Td, Tr, Th } from '@stadion/bright'
import { Box, Flex, Grid } from '@chakra-ui/layout'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { ModalIncomeCustomize2P1, ModalIncomeCustomize2P2, ModalIncomeCustomize2P3 } from '../../../utils/modals'
import PageWrapper from '../../PageWrapper'
import { toggle } from '../../../store/slices/quitModal'
import { useGetIncomeDistMutation } from '../../../store/api'
import { setCustomGraphSmileMode } from '../../../store/store'
import StackedBarChartIncome from '../../charts/StackedBarChartIncome'
import useUser from '../../../store/useUser'
import { dashboard, incomePlanSummary, incomeCustomizeConfirm, incomeCustomize1, incomeComparePlans } from '../../../routes'



function IncomeCustomize2() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const toggleModal = () => dispatch(toggle())
    const { isOpen } = useSelector(state => state.quitModal)
    const [ smileChart, setSmileChart ] = useState(undefined)
    const startAge = useSelector(state => state.incomePlan.startAge)
    const endAge = useSelector(state => state.incomePlan.endAge)
    const monthlyIncome = useSelector(state => state.incomePlan.monthlyIncome)
    const customStartAge = useSelector(state => state.incomePlan.customStartAge)
    const customEndAge = useSelector(state => state.incomePlan.customEndAge)
    const customMonthlyIncome = useSelector(state => state.incomePlan.customMonthlyIncome)
    const [ getIncomeDist, incomeDistResponse ] = useGetIncomeDistMutation()
    const [ getIncomeDistOrig, incomeDistResponseOrig ] = useGetIncomeDistMutation()

    const [ errors, setErrors ] = useState({})

    const urlFrom = location.state?.from;
    const editAll = location.state?.editAll;
   
    const user = useUser()
    
    useEffect(() => {
        getIncomeDistOrig()
    }, []) 
    
    useEffect(() => {
        let smileChartMode = 1;
        if(smileChart === 'yes') smileChartMode = 2;
        getIncomeDist({
            smileChartMode: smileChartMode,
            incomeStartAge: customStartAge > 0 ? customStartAge : startAge,
            incomeEndAge: customEndAge > 0 ? customEndAge : endAge,
            incomeOverride: customMonthlyIncome > 0 ? customMonthlyIncome : 0,
        })
    }, [user, smileChart])    
    
    useEffect(() =>
    {
        if (incomeDistResponse.data?.status?.rc < 0)
        {
            // When general error occurs not displayed above form appears to hang without displaying error.
            setErrors({ generic: incomeDistResponse.data?.status?.msg })
        }
        else {
            setErrors({})
        }
    }, [incomeDistResponse])
    
            
    const restart = () =>
    {
        toggleModal()
        navigate(dashboard)
    } 
    const handleBackClick = () =>
    {
        navigate(incomeCustomize1, {state: { from: urlFrom, editAll: editAll }})
    }          
    const handleContinueClick = () =>
    {
        let smileChartMode = 1;
        if(smileChart === 'yes') smileChartMode = 2;
        dispatch(setCustomGraphSmileMode( smileChartMode ))
        
        // Skip comparePlans if nothing changed from orig.
        if ((smileChart === 'yes') ||
            (incomeDistResponseOrig?.data?.incomeStartAge !== incomeDistResponse?.data?.incomeStartAge) ||
            (incomeDistResponseOrig?.data?.incomeEndAge !== incomeDistResponse?.data?.incomeEndAge) ||
            (incomeDistResponseOrig?.data?.incomeMonthlyAtRetire !== incomeDistResponse?.data?.incomeMonthlyAtRetire))
        {
           navigate(incomeComparePlans, {state: { from: urlFrom, editAll: editAll }})
        }
        else 
        {
            if((urlFrom !== undefined) && (urlFrom.toLowerCase() === incomePlanSummary.toLowerCase()))
                navigate(incomePlanSummary)
            else
                navigate(incomeCustomizeConfirm , {state: { editAll: editAll }})
        }
    }
    
    const errorEntries = Object.values(errors).filter(err => err)
    
    let incomeList = "";
    if(incomeDistResponse?.data?.incomeDistributionList) incomeList = incomeDistResponse?.data?.incomeDistributionList.filter(el => el.income > 0)
    const include401k = true;
    const includeSocSec = false;
    const includePension = false;
    
    // Rows for monthly table
    let renderedRows = "";
    if (incomeList)
    {
        renderedRows = incomeList?.map((rowData) =>
        {
            return (
                <Tr key={rowData.age}>
                    <Td>
                        <Text level="sm">{rowData.age}</Text>
                    </Td>
                    <Td>
                        <Text level="sm"> ${Math.round(rowData.income).toLocaleString()}</Text>
                    </Td>
                </Tr>
            );
        });
    }
    
    return (
        <PageWrapper pageTitle="Income Customize Based On Lifestyle">
            <Block palette="baseTwo" paddingY="4xl">
                <Flex height="100%" flexDir="column">
                    <PageHeadingBlock
                        pageHeading="Customize your amount based on your lifestyle in retirement"
                        subheading="Match expenses with changing needs as you go through retirement."
                        showDivider={true}
                    />

                    {
                        errorEntries.length > 0 &&
                        <ElevatedCard>
                            <Stack space="xl">
                                <Alert
                                    variant="negative"
                                    heading="A system error has occurred"
                                    content={
                                        errors.generic ||
                                        "Errors occurred during processing"}
                                />
                            </Stack>
                        </ElevatedCard>
                    }

                    <Box mt={8}>
                        <Grid
                            templateColumns={
                                [
                                    'auto',
                                    '1fr 1fr'
                                ]
                            }
                            columnGap="4"
                            rowGap="4"
                            minWidth={'600px'}
                            mb={4}
                        >
                            <Stack space="md">
                                <Box mt={3}>
                                    <RadioGroup
                                        label="Would you prefer your income to adjust or stay the same over time?"
                                        description="Studies suggest spending more early in retirement, scaling back in the middle years and increasing again in late retirement."
                                        isRequired={true}
                                        onChange={value => setSmileChart(value)}
                                        value={smileChart}
                                        defaultValue="no"
                                    >
                                        <Radio value="no" label="Adjust my income over time" />
                                        <Radio value="yes" label="Keep my income the same over time" />
                                    </RadioGroup>
                                </Box>
                                <Box mt={4}>
                                   {smileChart === 'yes' ? <ModalIncomeCustomize2P2 /> : <ModalIncomeCustomize2P1 />}
                                </Box>
                            </Stack>
                            <OutlineCard>
                                <Stack space="md">
                                    <Box>
                                        {!(incomeDistResponse.isLoading || incomeDistResponse.isFetching) ? (
                                            <StackedBarChartIncome
                                                barChartData={incomeList}
                                                include401k={include401k}
                                                includeSocSec={includeSocSec}
                                                includePension={includePension}
                                                narrowView={true}
                                                heightOverride="75%"
                                            />
                                        ) : (
                                            <PageLoading />
                                        )
                                        }
                                    </Box>
                                </Stack>
                            </OutlineCard>
                        </Grid>
                        <Stack space="md">
                            <Text as="p" level="xs">
                                Stadion provides estimates of periodic disbursements from a user’s retirement plan account based on a may choose to implement a “Smile” or “Straight Line” distribution strategy regarding their monthly income amounts. Academic research shows that real spending tends to be higher in early retirement years, decline over time, and increase towards later life, thus dubbed a “smile” curve (see “Exploring the Retirement Consumption Puzzle” by David Blanchett, Journal of Financial Planning | May 2014). The “Smile” method attempts to match this spending pattern with a modified distribution schedule that meets the needs of retirees. The “Smile” methodology considers a user's expected retirement age and expected income level in retirement when applying a multivariate formula as devised by Blanchette in the research referenced above. The result is a schedule of expected variable annual withdrawal rates across the user’s retirement period. The estimated dollar amount of withdrawals will vary based on the calculated smile distribution schedule. The distribution methodology (i.e. “Smile” vs. “Straight-line”) has no impact upon the optimization process, inputs, and assumptions used in the “Estimated Monthly Income Amount”. Regardless of the distribution methodology chosen or whether users elect to input their own monthly distribution amount or use the default estimated amount, the 90% success rate threshold applies for all users. “Smile” is the default methodology used in SLI but users have the option of overriding this strategy in favor of a constant distribution amount via the “Straight Line” strategy.
                            </Text>
                            <Text as="p" level="xs">
                                Straight-line distribution method: Users may choose to implement a “Smile” or “Straight Line” distribution strategy in regard to their monthly income amounts. The distribution methodology (i.e. “Smile” vs. “Straight-line”) has no impact upon the optimization process used in the “Estimated Monthly Income Amount”. Regardless of the distribution methodology chosen or whether users elect to input their own monthly distribution amount or use the default estimated amount, the 90% success rate threshold applies for all users. “Smile” is the default methodology used in SLI but users have the option of overriding this strategy in favor of “Straight-line”.
                            </Text>
                            <Text as="p" level="xs">
                                The timeframe for any disbursement estimates shown may not be available based on your Plan's service arrangement with its recordkeeper. Please confirm check with your Plan's administrator to learn what is available to you.
                            </Text>
                        </Stack>
                    </Box>

                    <Box mt={5} mb={8}>
                        <Accordion allowToggle={true}>
                            <AccordionItem>
                                <AccordionButton>
                                    Cash flow table <ModalIncomeCustomize2P3 />
                                </AccordionButton>
                                <AccordionPanel px="0" py="2">
                                    <Stack space="md">
                                        <Table>
                                            <Thead>
                                                <Tr>
                                                    <Th>
                                                        <Text as="b" level="sm">Age</Text>
                                                    </Th>
                                                    <Th>
                                                        <Text as="b" level="sm">401(k) monthly income</Text>
                                                    </Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>{renderedRows}</Tbody>
                                        </Table>
                                    </Stack>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Box>



                    <Adjacent space="md">
                        <Button onClick={handleBackClick} variant="secondaryButton">
                            Back
                        </Button>
                        <Button onClick={handleContinueClick}>
                            Continue
                        </Button>
                    </Adjacent>

                    <Box mt={8} mb={4}>
                        <QuitAndRestartModal
                            quitAndRestartLinkText="Quit and return to the dashboard"
                            quitAndRestartModalTitle="Are you sure you want to quit?"
                            quitAndRestartModalDescription="If you quit, all your unsaved information will be lost."
                            quitAndRestartModalCancelButtonText="Cancel"
                            quitAndRestartModalConfirmButtonText="Yes, quit"
                            onQuitAndRestartConfirm={restart}
                            isOpen={isOpen}
                            onClose={toggleModal}
                            onOpen={toggleModal}
                        />
                    </Box>
                </Flex>
            </Block>
        </PageWrapper>
    )
}

export default IncomeCustomize2
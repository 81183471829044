import React, { useEffect, useState } from 'react'
import { Alert, CurrencyInput, ElevatedCard, NumberInput, PageHeadingBlock, QuitAndRestartModal, Radio, RadioConditional, RadioGroup, Stack, Block, Text } from '@stadion/bright'
import { Box, Flex } from '@chakra-ui/layout'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { ModalIncomeCustomizePension } from '../../../utils/modals'
import PageWrapper, { defaultFooter } from '../../PageWrapper'
import IncomeStickyFooter from './IncomeStickyFooter'
import { toggle } from '../../../store/slices/quitModal'
import { setCustomPensionStartAge, setCustomMonthlyPension, setIncludePension } from '../../../store/store'
import useUser from '../../../store/useUser'
import { dashboard, incomePlanSummary, incomeCustomizeSocsec } from '../../../routes'



function IncomeCustomizePension()
{
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const toggleModal = () => dispatch(toggle())
    const { isOpen } = useSelector(state => state.quitModal)
    const customStartAge = useSelector(state => state.incomePlan.customStartAge)
    const customSocsecStartAge = useSelector(state => state.incomePlan.customSocsecStartAge)
    const customPensionStartAge = useSelector(state => state.incomePlan.customPensionStartAge)
    const customMonthlyIncome = useSelector(state => state.incomePlan.customMonthlyIncome)
    const customMonthlySocsec = useSelector(state => state.incomePlan.customMonthlySocsec)
    const customMonthlyPension = useSelector(state => state.incomePlan.customMonthlyPension)
    const includeSocsec = useSelector(state => state.incomeAccount.includeSocsec)
    const includePension = useSelector(state => state.incomeAccount.includePension)
    const startAge = useSelector(state => state.incomePlan.startAge)
    const monthlyIncome = useSelector(state => state.incomePlan.monthlyIncome)
    const acceptedPlan = useSelector(state => state.incomePlan.acceptedPlan)
    const [ factorPension, setFactorPension] = useState(includePension === true ? 'yes' : 'no')
    const [ pensionStartAge, setPensionStartAge] = useState(customPensionStartAge)
    const [ monthlyPension, setMonthlyPension] = useState(customMonthlyPension)
    
    const user = useUser()

    const [errors, setErrors] = useState({})
    
    
    const urlFrom = location.state?.from
    let backHref = incomeCustomizeSocsec
    if(urlFrom !== undefined) backHref = urlFrom
    const editAll = location.state?.editAll;
    

    const restart = () =>
    {
        toggleModal()
        navigate(dashboard)
    }

    const handleBackClick = () =>
    {
        navigate(backHref, {state: { editAll: editAll }})
    }
    const handleContinueClick = () =>
    {
        if(factorPension === 'yes') {
            dispatch(setCustomMonthlyPension(Number.parseInt(monthlyPension)))
            dispatch(setCustomPensionStartAge(pensionStartAge))
            dispatch(setIncludePension(true));
        }
        else {
            dispatch(setCustomMonthlyPension(0))
            dispatch(setCustomPensionStartAge(0)) 
            dispatch(setIncludePension(false));
        }
               
        navigate(incomePlanSummary)
    }

    const handleChangePension = (value) =>
    {
        setFactorPension(value)
        const incPension = value === 'yes';
        dispatch(setIncludePension(incPension));
    }
    
    useEffect(() => {
        if (pensionStartAge === 0)
        {
            setPensionStartAge(startAge)
        }
        if(monthlyPension === 0) {
            // Default monthly from user yearly pension if not set
            if(user?.pqdiaReq?.pensions > 0) {
                setMonthlyPension(user.pqdiaReq.pensions / 12)
            }
        }
    }, [])

       
    let incomeStartAge = acceptedPlan > 0 ? customStartAge : startAge
    if(incomeStartAge === 0) incomeStartAge = startAge
    let incomeMonthly = acceptedPlan > 0 ? customMonthlyIncome : monthlyIncome
    if (incomeMonthly === 0) incomeMonthly = monthlyIncome
    
    const footer =
        <IncomeStickyFooter
            backHandler={handleBackClick}
            backLabel={'Back'}
            continueHandler={handleContinueClick}
            continueLabel={'Continue'}
            income401k={incomeMonthly}
            start401k={incomeStartAge}
            includeSocsec={includeSocsec}
            incomeSocsec={customMonthlySocsec}
            startSocsec={customSocsecStartAge}
            includePension={includePension}
            incomePension={monthlyPension}
            startPension={pensionStartAge}
        />


    const errorEntries = Object.values(errors).filter(err => err)

    return (
        <PageWrapper footer={footer} pageTitle="Income Customize Pension">
            <Block palette="baseTwo" paddingY="4xl">
                <Flex height="100%" flexDir="column">
                    <PageHeadingBlock
                        pageHeading="Tell us how you plan on managing your own Pension income"
                        subheading="Calculate how periodic pension payments could impact your monthly retirement income."
                        showDivider={true}
                    />

                    {
                        (errorEntries.length > 0) &&
                        <ElevatedCard>
                            <Stack space="xl">
                                <Alert
                                    variant="negative"
                                    heading="An error has occurred"
                                    content={
                                        errors.generic ||
                                        "Errors occurred during processing"}
                                />
                            </Stack>
                        </ElevatedCard>
                    }

                    <Box mt={8}>
                        <Stack space="md">
                            <Text as="p">
                               Including your pension income may give you additional insight into your retirement income. If you have any questions regarding your pension, please contact the employer with which it is associated for additional details.
                            </Text>
                            <ModalIncomeCustomizePension />
                        </Stack>
                    </Box>


                    <Box mt={5}>
                        <RadioGroup
                            label="Do you want to consider your estimated pension income?"
                            // description="Stadion can only offer educational information about your pension as it is outside of your 401(k) plan."
                            isRequired={true}
                            onChange={factorPension => handleChangePension(factorPension)}
                            value={factorPension}
                        >
                            <RadioConditional value="yes" label="Yes" description="Factor in my pension for education">
                                <NumberInput
                                    label="Start age"
                                    description="By default, we've aligned it with your retirement age"
                                    value={pensionStartAge}
                                    isRequired={true}
                                    onChange={pensionStartAge => setPensionStartAge(pensionStartAge)}
                                    min={50}
                                    max={100}
                                    step={1}
                                />
                                <Box mt={5}>
                                    <CurrencyInput
                                        label="Monthly pension income"
                                        description="This number is calculated by dividing the annual dollar amount you entered for Pension and deferred compensation by twelve. You can update this estimate at any time"
                                        value={
                                            fixNaN(
                                                (typeof monthlyPension === 'number' ?
                                                    monthlyPension.toLocaleString() :
                                                    monthlyPension))
                                        }
                                        isRequired={true}
                                        onChange={monthlyPension => setMonthlyPension(Number.parseInt(monthlyPension || 0))}
                                        step={100}
                                        currencyLabel="$"
                                        characters={12}
                                    />
                                </Box>
                            </RadioConditional>
                            <Radio value="no" label="No" description="Continue without showing how my pension will impact my estimated monthly income." />
                        </RadioGroup>
                    </Box>

                    <Box mt={8} mb={4}>
                        <QuitAndRestartModal
                            quitAndRestartLinkText="Quit and return to the dashboard"
                            quitAndRestartModalTitle="Are you sure you want to quit?"
                            quitAndRestartModalDescription="If you quit, all your unsaved information will be lost."
                            quitAndRestartModalCancelButtonText="Cancel"
                            quitAndRestartModalConfirmButtonText="Yes, quit"
                            onQuitAndRestartConfirm={restart}
                            isOpen={isOpen}
                            onClose={toggleModal}
                            onOpen={toggleModal}
                        />
                    </Box>
                </Flex>
            </Block>
        </PageWrapper>
    )
}

function fixNaN(val)
{
    val = +Number.parseInt(val?.replace(/,/g, '')) || 0; // Remove commas in currency
    return val.toLocaleString();
}

export default IncomeCustomizePension
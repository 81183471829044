import { Grid } from '@chakra-ui/layout'
import { Alert, Button, ElevatedCard, Frame, Link, MainWrapper, PageLoading, Stack } from '@stadion/bright'
import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useFillOutQuestionnaireMutation } from '../../../store/api'
import { setToAResponse } from '../../../store/slices/toa'
import { toggle } from '../../../store/slices/quitModal'
import DonutChart from '../../charts/DonutChart';
import RiskProfileChart from '../../charts/RiskProfileChart';
import useUser from '../../../store/useUser'
import { wizardRoutes } from '../../../routes'


function Review() {
    const dispatch = useDispatch()
    const questionnaire = useSelector(state => state.questionnaire)
    const [ fillOutQuestionnaire, questionnaireResultResponse ] = useFillOutQuestionnaireMutation()
    const [ errors, setErrors ] = useState({})
    const [ allocationDataList, setAllocationDataList ] = useState(null);

    const user = useUser()
    const answers = Object.entries(questionnaire)

    
    useEffect(() => {
        const rtqAnswers = answers.reduce((rtqAnswers, entry) => [ ...rtqAnswers, ...entry ], []) 

        fillOutQuestionnaire({
            palette: 3,
            sendModelsToS3: true,
            rtqAnswers
        })


    }, [])
    
    useEffect(() => {
        if (questionnaireResultResponse.isSuccess && questionnaireResultResponse.data?.status?.rc >= 0) {
            dispatch(
                setToAResponse({
                    toaAdv: questionnaireResultResponse.data?.toaAdv,
                    toaUrl: questionnaireResultResponse.data?.toaUrl,
                    toaUrlText: questionnaireResultResponse.data?.toaUrlText
                })
            );

            let aList = questionnaireResultResponse.data?.allocationDataList;
            setAllocationDataList(aList);
        }
        else {
            // When general error occurs not displayed above form appears to hang without displaying error.
            // console.log(questionnaireResultResponse.data?.status?.rc, questionnaireResultResponse.data?.status?.msg );
            setErrors({ generic: questionnaireResultResponse.data?.status?.msg })
        }
    }, [ questionnaireResultResponse ])
                 

    if (answers.length !== 9)
        return <Navigate to={ wizardRoutes.questions1 } />

              
    if (questionnaireResultResponse.isLoading)
        return (
            <MainWrapper>
                <PageLoading />
            </MainWrapper>
        )
        
    const errorEntries = Object.values(errors).filter(err => err)
 
    return (
        <Frame width={ [ 'full' ] } paddingY="4xl">
            <Stack space="4xl">
                {
                    errorEntries.length > 0 &&
                    <Alert
                        variant="negative"
                        heading="A system error has occurred"
                        content={
                            errors.generic ||
                            "Errors occurred during processing"}
                    />
                }
                <ElevatedCard>
                    <RiskProfileChart riskProfile={ questionnaireResultResponse.data?.user?.pqdiaResp?.riskProfileText } />
                </ElevatedCard>
                
                <ElevatedCard>
                    <DonutChart user={user} allocationDataList={allocationDataList} allowAgeChange={true} />
                </ElevatedCard>

                <Grid templateColumns={ [ '100%', 'auto auto' ] } justifyContent={ 'start' } rowGap="4" columnGap="4">
                    <Button onClick={ () => dispatch(toggle()) } variant="secondaryButton">
                        Retake the questionnaire
                    </Button>
                    <Link href={wizardRoutes.toa} variant="primaryButton">
                        Continue
                    </Link>
                </Grid>
            </Stack>
        </Frame>
    )    
}

export default Review
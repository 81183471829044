import { Box, Flex, Grid } from '@chakra-ui/layout'
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, OutlineCard, PageHeadingBlock, Stack, Block, Link, Text, PageLoading, NumberInput, PercentageInput } from '@stadion/bright'
import React, { useEffect, useState } from 'react'
import { ModalDashboardTabIncome1, ModalDashboardTabOverview1, ModalDashboardTab401k1, ModalDashboardTab401k2 } from '../../utils/modals'
import { useSelector } from 'react-redux'
import ColumnChartIncome from '../charts/ColumnChartIncome';
import RiskProfileChart from '../charts/RiskProfileChart';
import DonutChart from '../charts/DonutChart'
import useUser from '../../store/useUser'
import { questions1 } from '../../routes'




function DashboardTab401k({ dashboardResponse, websiteModelsResponse}) {
    const allocationDataList = useSelector(state => state.donutData.allocationDataList)
    const [ retirementAge, setRetirementAge ] = useState(67)
    const [ contributionRate, setContributionRate ] = useState(6)
    const user = useUser()

    useEffect(() => {
        if (user) {
            // If retired, use current age (no chartData pre-retirement)
            setRetirementAge(user?.pqdiaReq?.expectedRetirementAge < user?.pqdiaReq?.age ? user?.pqdiaReq?.age : user?.pqdiaReq?.expectedRetirementAge)
            setContributionRate(user?.pqdiaReq?.partDeferralPercentage)
        }
    }, [ user ])

    const calcData = dashboardResponse.data?.chartData?.find(cd => (cd.retirementAge === parseInt(retirementAge)) && (cd.deferalPercent === parseInt(contributionRate)))
    
    // console.log(websiteModelsResponse?.data)
    //console.log(user)
    //console.log(dashboardResponse?.data?.user)

    
    return (
        <Flex flexGrow={1} flexDir="column">
            <Block>
                <Stack space="lg">
                    <PageHeadingBlock
                        pageHeading={"401(k) Savings"}
                        palette='baseTwo'
                        showDivider={false}
                    />
                    <OutlineCard>
                        <Stack space="xl">
                            <Box mt={1}>
                                <Grid
                                    templateColumns={
                                        [
                                            'auto',
                                            '1fr 1px 1fr 1px 1fr'
                                        ]
                                    }
                                    columnGap="4"
                                    rowGap="4"
                                    minWidth={'600px'}
                                >
                                    <Stack space="sm">
                                        <Box
                                            h="50px"
                                            sx={{
                                                "@media screen and (max-width: 600px)": {
                                                    height: 15,
                                                },
                                            }}
                                        >
                                            <Text level="sm" as="b" variant="primary">
                                                Current 401(k) balance:
                                            </Text>
                                        </Box>
                                        <Box>
                                            <Text level="xl" as="b" variant="primary">
                                                ${user ? (Math.round(user?.pqdiaReq?.vendorAum).toLocaleString()) : "0"}
                                            </Text>
                                        </Box>
                                    </Stack>
                                    <Border />
                                    <Stack space="sm">
                                        <Box>
                                            <Text level="sm" as="b" variant="primary">
                                                Estimated 401(k) balance at retirement:
                                            </Text>
                                        </Box>
                                        <Box>
                                            <Text level="xl" as="b" variant="primary">
                                                ${calcData ? (Math.trunc(calcData?.totalRetireValue).toLocaleString()) : "0"}
                                                { /* ${ dashboardResponse?.data?.incomeAcctValueAtRetire ? (Math.round(dashboardResponse?.data?.incomeAcctValueAtRetire).toLocaleString()) : "0"} */}
 
                                            </Text>
                                            <ModalDashboardTabOverview1 />
                                        </Box>
                                    </Stack>
                                    <Border />
                                    <Stack space="sm">
                                        <Box
                                            h="50px"
                                            sx={{
                                                "@media screen and (max-width: 600px)": {
                                                    height: 15,
                                                },
                                            }}
                                        >
                                            <Text level="sm" as="b" variant="primary">
                                                Years to retirement:
                                            </Text>
                                        </Box>
                                        <Box>
                                            <Text level="xl" as="b" variant="primary">
                                                {user ? (Math.round(retirementAge - user?.pqdiaReq?.age).toLocaleString()) : "0"}
                                                {/*{user ? (Math.round(user.expectedRetirementAge - user.age).toLocaleString()) : "0"} */}
                                            </Text>
                                            <ModalDashboardTab401k1 />
                                        </Box>
                                    </Stack>
                                </Grid>
                                {!(dashboardResponse.isLoading || dashboardResponse.isFetching) ? (
                                    <div>
                                        <Box mt={5}>
                                            <ColumnChartIncome calcData={calcData} contributionRate={contributionRate} retirementAge={retirementAge} currentAge={user?.pqdiaReq?.age} />
                                        </Box>
                                    </div>
                                ) : (
                                    <PageLoading />
                                )
                                }
                            </Box>
                            <Box mt={5}>
                                <Stack space="sm">
                                    <Text as="p" level="xs">
                                       Unless otherwise noted or adjusted by you, estimated 401(k) balance at retirement uses an estimated, future value 
                                       calculation using the following assumptions: a retirement age of 67, annual salary increases based on inflation 
                                       beginning with your current salary, a contribution rate of 6% with no matching contributions, forward-looking asset 
                                       class level expected returns based on capital markets assumptions which include inflation, and expected portfolio 
                                       allocations from present day to your specified retirement date depending on your risk profile as determined by 
                                       completing Stadion’s risk tolerance questionnaire. Annual contributions are applied as a lump sum at the end of 
                                       each calendar year. Estimated market performance is compounded annually.
                                    </Text>
                                    <ModalDashboardTab401k2 />
                                    <Text level="sm" as="b" variant="primary">
                                        Play around with your growth strategy.
                                    </Text>
                                    <Text as="p" level="xs">
                                        Adjust your contribution rate and retirement age to see how it could affect your estimated 401(k) balance in retirement.
                                    </Text>

                                    <Box>
                                       <ModalDashboardTabIncome1/>
                                    </Box>
                                    
                                    <Grid rowGap="3" templateColumns={['auto', '1fr 1fr']}>
                                        <NumberInput
                                            label="Retirement age"
                                            value={retirementAge}
                                            isRequired={true}
                                            onChange={setRetirementAge}
                                            min={55}
                                            max={75}
                                            step={1}
                                        />
                                        <PercentageInput
                                            label="Contribution rate"
                                            value={contributionRate}
                                            isRequired={true}
                                            onChange={setContributionRate}
                                            min={0}
                                            max={30}
                                            step={1}
                                        />
                                    </Grid>
                                </Stack>
                            </Box>
                        </Stack>
                    </OutlineCard>

                    <Box>
                        <Accordion allowToggle={true}>
                            <AccordionItem>
                                <AccordionButton>
                                    Risk assessment
                                </AccordionButton>
                                <AccordionPanel px="0" py="2">
                                    <Stack space="md">
                                        {!(websiteModelsResponse.isLoading || websiteModelsResponse.isFetching) ? (
                                            <div>
                                                <Box mt={5}>
                                                    {/* <RiskProfileChart riskProfile={websiteModelsResponse.data?.user?.pqdiaResp?.riskProfileText} /> */}
                                                    <RiskProfileChart riskProfile={websiteModelsResponse.data?.user?.pqdiaResp?.riskProfileText} />
                                                </Box>
                                            </div>
                                        ) : (
                                            <PageLoading />
                                        )
                                        }                                        
                                        <Link
                                            href={questions1}
                                            variant="secondaryButton"
                                            level="2xs"
                                        >
                                            Retake the questionnaire
                                        </Link>
                                    </Stack>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Box>

                    <Box>
                        <Accordion allowToggle={true}>
                            <AccordionItem>
                                <AccordionButton>
                                    Your retirement portfolio
                                </AccordionButton>
                                <AccordionPanel px="0" py="2">
                                    {!(websiteModelsResponse.isLoading || websiteModelsResponse.isFetching) ? (
                                        <div>
                                            <Box mt={5}>
                                                <DonutChart user={user} allocationDataList={allocationDataList} allowAgeChange={true} />
                                            </Box>
                                        </div>
                                    ) : (
                                        <PageLoading />
                                    )
                                    }
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Box>
                </Stack>
            </Block>
        </Flex>
    )
}

function Border() {
    return <div style={ { backgroundColor: '#E0E0E0', minHeight: '1px' } }></div>
}

export default DashboardTab401k
import { Flex, Grid } from '@chakra-ui/layout'
import { PageHeadingBlock, Stack, Block,  MainWrapper, Link, Text, List, ListTextItem } from '@stadion/bright'
import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import PageWrapper from '../PageWrapper'
import routes, { signup } from '../../routes'
import WistiaPlayer from '../WistiaPlayer'

function Home() {
    const { sessionToken } = useSelector(state => state.token)
    
    if (sessionToken)
        return <Navigate to="/participant/dashboard" />

    return (
        <PageWrapper pageTitle="StoryLine | A Customizable Managed Accounts Solution | Stadion">
            <MainWrapper palette="baseTwo">
                <Flex height="100%" flexDir="column">
                    <PageHeadingBlock
                        pageHeading="StoryLine"
                        palette='baseThree'
                        subheading='The next chapter in retirement solutions'
                        showDivider={false}
                    />
                    <Flex flexGrow={1} flexDir="column">
                        <Block palette="baseTwo" paddingY="6xl">
                            <Stack space="6xl">
                                <Text level="lg" variant="primary">
                                    Every organization—and each of its employees—has a unique story. We think these stories should be a part of their retirement plan.
                                </Text>
                                <Stack space="md">
                                    <Text as="p">
                                        Most people don’t have the time, desire, or expertise to manage their retirement accounts.
                                    </Text>
                                    <Text as="p">
                                        That’s why we created StoryLine. It’s an easy and personalized retirement management strategy that factors in who you are as an individual.
                                    </Text>
                                </Stack>
                                <Grid templateColumns={['1fr', 'auto auto']} justifyContent="start" rowGap="4" columnGap="4">
                                    <Link href={routes.login} variant="secondaryButton" isFullWidthForMobile={true}>
                                        Log in
                                    </Link>
                                    <Link href={routes.signup} variant="primaryButton" isFullWidthForMobile={true}>
                                        Get started
                                    </Link>
                                </Grid>
                                <Text as="p">
                                    Take two minutes to learn more about StoryLine’s tailored retirement solutions.
                                </Text>
                                <div style={{ maxWidth: 576, outline: '1px solid #bdbdbd' }}>
                                    <WistiaPlayer
                                        //videoId="u5dw0vy3dz" 
                                        videoId="1ei1ydvnrh"
                                        wrapper="wistia-player-container-1"
                                    />
                                </div>
                                <Text level="lg" variant="primary">
                                    StoryLine’s goals are simple
                                </Text>
                                <List>
                                    <ListTextItem>
                                        Make retirement planning easier and less stressful for you.
                                    </ListTextItem>
                                    <ListTextItem>
                                        Customize your account to reflect your story—who you are, your financial situation, your tolerance for risk, and your life and retirement goals.
                                    </ListTextItem>
                                    <ListTextItem>
                                        Help you adequately prepare for the retirement you want.
                                    </ListTextItem>
                                </List>
                                <Text level="lg" variant="primary">
                                    How StoryLine works
                                </Text>
                                <List>
                                    <ListTextItem>
                                        <Text as="b">Investment plan: </Text> Based on your profile and personal information, we’ll put you into the investment plan that is best suited to who you are.
                                    </ListTextItem>
                                    <ListTextItem>
                                        <Text as="b">Dashboard: </Text> StoryLine’s online dashboard helps you personalize your retirement planning experience even further. Use it to:
                                        <List>
                                            <ListTextItem>
                                                Play with your contribution rates and see the effects it can have over time.
                                            </ListTextItem>
                                            <ListTextItem>
                                                Add your other financial information for a more complete picture.
                                            </ListTextItem>
                                        </List>
                                    </ListTextItem>
                                    <ListTextItem>
                                        <Text as="b">Ongoing communication: </Text> We’ll periodically check in with you via email to help you stay on track. Knowing that we’ll prompt you gives you one less thing to think about. If your email address changes, make sure to let us know. You can reach us at <Link href="mailto:support@stadionmoney.com" variant="primaryLink">support@stadionmoney.com.</Link>
                                    </ListTextItem>
                                    <ListTextItem>
                                        <Text as="b">Support: </Text> We provide high-quality, easy-to-access telephone support from our U.S.–based investor service department near Atlanta, Georgia. A friendly, knowledgeable service adviser is happy to answer your questions about your StoryLine account or provide the information you need.
                                    </ListTextItem>
                                </List>
                                <Text as="strong" level="xl" variant="primary">
                                    What to do next
                                </Text>
                                <Stack space="md">
                                    <Text as="p">
                                        Once your employer selects StoryLine as an option for your company’s retirement plan, you’ll receive information on how to further customize your account online.
                                    </Text>
                                    <Text as="p">
                                        Then, once you enroll in StoryLine (either during an enrollment meeting or after meeting with your company’s financial advisor), you’ll be emailed a link that will allow you to log in to StoryLine and further personalize your profile.
                                    </Text>
                                    <Text as="p">
                                        If you have questions or aren’t certain about what to do next, feel free to <Link href={routes.contact} variant="primaryLink">contact us.</Link>
                                    </Text>
                                </Stack>
                                <Stack space="md">
                                    <Text as="p" level="2xs">
                                        There is no guarantee of the future success of any Stadion account. Investments are subject to risk, and any of Stadion’s investment strategies may lose value. The visuals shown above are for illustrative purposes only and do not guarantee a certain level of performance or success.
                                    </Text>
                                    <Text as="p" level="2xs">
                                        Stadion Money Management, LLC (“Stadion”) is an investment adviser registered with the U.S. Securities and Exchange Commission. Registration does not imply a certain level of skill or training. More information about Stadion’s investment advisory services can be found in its Form ADV Part 2, which is available upon request.
                                    </Text>
                                </Stack>
                            </Stack>
                        </Block>
                    </Flex>
                </Flex>
            </MainWrapper>
        </PageWrapper>
    )
}

export default Home
import { Adjacent, Alert, Block, Button, Frame, Link, Stack, Text, useNavigation } from '@stadion/bright'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import RadioInput from '../../RadioInput'
import { answerQuestion } from '../../../store/store'
import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router'
import { wizardRoutes } from '../../../routes'

function Questions3() {
    const navigation = useNavigation()
    const questionnaire = useSelector(state => state.questionnaire)
    const dispatch = useDispatch()
    const [ errors, setErrors ] = useState({})
    
    const checkForm = () => {
        if (questionnaire.q5 && questionnaire.q6)
            navigation.redirectTo(wizardRoutes.questions4)
        else
            setErrors({
                q5: questionnaire.q5 ? null : [ 'Rating is required' ],
                q6: questionnaire.q6 ? null : [ 'Rating is required' ]
            })
    }

    if (!questionnaire.q3 && !questionnaire.q4)
        return <Navigate to={ wizardRoutes.questions2 } />

    const hasErrors = Object.values(errors).some(e => e)

    return (
        <Block palette="baseTwo" paddingY="4xl">
            <Frame width={ [ 'full' ] }>
                <Stack space="6xl">
                    { hasErrors && <Alert variant="negative" heading="Questionnaire answers missing" /> }
                    <RadioInput
                        label="5. I would rather sleep at night knowing my money is invested securely than take big risks."
                        isRequired={ true }
                        name="q5"
                        value={ questionnaire.q5 }
                        onChange={ answer => dispatch(answerQuestion({ questionNumber: 'q5', answer })) }
                        errors={ errors.q5 || [] }
                    />
                    <RadioInput
                        label="6. I don’t like taking risks - especially with my money."
                        isRequired={ true }
                        name="q6"
                        value={ questionnaire.q6 }
                        onChange={ answer => dispatch(answerQuestion({ questionNumber: 'q6', answer })) }
                        errors={ errors.q6 || [] }
                    />
                    <Adjacent space="md">
                        <Link href={ wizardRoutes.questions2 } variant="secondaryButton">
                            Back
                        </Link>
                        <Button onClick={ checkForm }>
                            Next
                        </Button>
                    </Adjacent>
                </Stack>
            </Frame>
        </Block>
    )    
}

export default Questions3
import React, { useEffect, useState } from 'react'
import { Alert, CurrencyInput, ElevatedCard, NumberInput, PageHeadingBlock, QuitAndRestartModal, Stack, Block, Button, PageLoading, Adjacent, Text } from '@stadion/bright'
import { Box, Flex } from '@chakra-ui/layout'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { ModalIncomeCustomize1 } from '../../../utils/modals'
import PageWrapper from '../../PageWrapper'
import { toggle } from '../../../store/slices/quitModal'
import { useGetIncomeDistMutation } from '../../../store/api'
import { setStartAge, setEndAge, setMonthlyIncome, setCustomStartAge, setCustomEndAge, setCustomMonthlyIncome, setCustomGraphSmileMode, setPtileOverride, setRiskProfileOverride } from '../../../store/store'
import { dashboard, incomeStep1, incomeCustomize2, incomeComparePlans } from '../../../routes'


function IncomeCustomize1()
{
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const toggleModal = () => dispatch(toggle())
    const { isOpen } = useSelector(state => state.quitModal)
    const planAccepted = useSelector(state => state.incomePlan.planAccepted)
    const customStartAge = useSelector(state => state.incomePlan.customStartAge)
    const customEndAge = useSelector(state => state.incomePlan.customEndAge)
    const customMonthlyIncome = useSelector(state => state.incomePlan.customMonthlyIncome)
    const startAge = useSelector(state => state.incomePlan.startAge)
    const endAge = useSelector(state => state.incomePlan.endAge)
    const monthlyIncome = useSelector(state => state.incomePlan.monthlyIncome)
    const [getIncomeDist, incomeDistResponse] = useGetIncomeDistMutation()
    const [getIncomeDistOrig, incomeDistResponseOrig] = useGetIncomeDistMutation()
    const [getIncomeDistInit, incomeDistResponseInit] = useGetIncomeDistMutation()
    const [inputStartAge, setInputStartAge] = useState(customStartAge)
    const [inputEndAge, setInputEndAge] = useState(customEndAge)
    const [inputMonthly, setInputMonthly] = useState(customMonthlyIncome)
    const customGraphSmileMode = useSelector(state => state.incomePlan.customGraphSmileMode)
    const [errors, setErrors] = useState({})

    const urlFrom = location.state?.from;
    let backHref = incomeStep1
    if(urlFrom !== undefined) backHref = urlFrom
    const editAll = location.state?.editAll;
    

    useEffect(() =>
    {
        
        // Set defaults if not yet set 
        if (inputStartAge === 0)
        {
            setInputStartAge(startAge)
        }
        if (inputEndAge === 0)
        {
            // Add 5 years if defaulting, otherwise keep accepted plan value
            // if(planAccepted === true) setInputEndAge(endAge)
            // else setInputEndAge(endAge + 5)
            setInputEndAge(endAge)
        }
        if ((inputMonthly === 0) && (planAccepted === true))
        {
            setInputMonthly(monthlyIncome)
        }

        getIncomeDistOrig()
        if (planAccepted !== true)
        {
            // First time in/no accepted plan get init monthly for end age
            getIncomeDistInit({
                smileChartMode: 1,
                incomeStartAge: inputStartAge,
                incomeEndAge: endAge
                // incomeEndAge: endAge + 5
            })
        }
    }, []
    )
       
    
    useEffect(() =>
    {
        if ((inputEndAge > 0) && ((inputMonthly === 0) || (inputMonthly === undefined))) {
           setInputMonthly(incomeDistResponseInit?.data?.incomeMonthlyAtRetire)
        }
    }, [incomeDistResponseInit?.data]
    )
    
    
    useEffect(() =>
    {
        if (incomeDistResponse.data?.status?.rc < 0)
        {
            // When general error occurs not displayed above form appears to hang without displaying error.
            setErrors({ generic: incomeDistResponse.data?.status?.msg })
        }
    }, [incomeDistResponse])
    
    
    const restart = () =>
    {
        toggleModal()
        navigate(dashboard)
    }
    
    const handleUpdateStartAge = (startAgeIn) =>
    {
        setInputStartAge(startAgeIn);
    }
    const handleUpdateEndAge = (endAgeIn) =>
    {
        setInputEndAge(endAgeIn);
    }
    const handleUpdateMonthly = (monthlyIn) =>
    {
        setInputMonthly(monthlyIn);
    }
    const handleBackClick = () =>
    {
        navigate(backHref, {state: { editAll: editAll }})
    }
    const handleContinueClick = async () =>
    {
        if(Number.parseInt(inputEndAge) <= Number.parseInt(inputStartAge))
        {
            setErrors({ inputEndAge: 'End age cannot be greater than start age' })
        }
        else {
            // Check if ages or monthly income has changed since original
            let smileChartMode = 1
            if((incomeDistResponseOrig?.data?.incomeStartAge        !== Number.parseInt(inputStartAge)) ||
               (incomeDistResponseOrig?.data?.incomeEndAge          !== Number.parseInt(inputEndAge)) ||
               (incomeDistResponseOrig?.data?.incomeMonthlyAtRetire !== Number.parseInt(inputMonthly)))
            {
                smileChartMode = 2   // straight line    
            }
            let distData = await getIncomeDist({
                smileChartMode: smileChartMode,
                incomeStartAge: inputStartAge,
                incomeEndAge: inputEndAge,
                incomeOverride: inputMonthly
            })
            

            let validateError = distData.data?.status?.rc;
            if (validateError >= 0)
            {                   
                // Check if values have been reset to orig, then zero out
                let sAge = inputStartAge;
                if(incomeDistResponseOrig?.data?.incomeStartAge === Number.parseInt(inputStartAge)) {
                    sAge = 0;
                    dispatch(setStartAge(incomeDistResponseOrig?.data?.incomeStartAge))
                }
                let eAge = inputEndAge;
                // if((incomeDistResponseOrig?.data?.incomeEndAge + 5) === Number.parseInt(inputEndAge)) eAge = 0;  // Note added 5 to orig
                if((incomeDistResponseOrig?.data?.incomeEndAge) === Number.parseInt(inputEndAge)) {
                    eAge = 0;  
                    dispatch(setEndAge(incomeDistResponseOrig?.data?.incomeEndAge))
                }
                let monthly = inputMonthly;
                if((incomeDistResponseOrig?.data?.incomeMonthlyAtRetire === Number.parseInt(inputMonthly)) && (sAge === 0) && (eAge === 0)) {
                    monthly = 0;
                    dispatch(setMonthlyIncome(Number.parseInt(incomeDistResponseOrig?.data?.incomeMonthlyAtRetire))) 
                }
                
                dispatch(setCustomStartAge(Number.parseInt(sAge)))
                dispatch(setCustomEndAge(Number.parseInt(eAge)))
                dispatch(setCustomMonthlyIncome(Number.parseInt(monthly))) 
                
                
                if((distData?.data?.riskLevelOverride > 0) || (distData?.data?.riskProfileOverride > 0))
                {
                    // Straight line when ptile or risk profile override
                   smileChartMode = 2   
                }
                dispatch(setCustomGraphSmileMode( smileChartMode ))
                dispatch(setPtileOverride(distData?.data?.riskLevelOverride))
                dispatch(setRiskProfileOverride(distData?.data?.riskProfileOverride))

                setErrors({});
                
                // If ptile or risk profile changed, skip customize2 - go to compare/select plan
                if(smileChartMode !== 2) {
                   navigate(incomeCustomize2, { state: { from: urlFrom, editAll: editAll } })
                }
                else {
                    navigate(incomeComparePlans, {state: { from: urlFrom, editAll: editAll }})
                }
            }
            else
            {
                console.log("Validation error occurred, ", validateError);
            }
        }
    }

    const errorEntries = Object.values(errors).filter(err => err)


    return (
        <PageWrapper pageTitle="Income Customize 401(k)">
            <Block palette="baseTwo" paddingY="4xl">
                <Flex height="100%" flexDir="column">
                    <PageHeadingBlock
                        pageHeading="Customize your own 401(k) income amount"
                        subheading="Calculate your income by adjusting the options below. After that, you can compare your income options and decide which one fits you the best."
                        showDivider={true}
                    />

                    {(errorEntries.length > 0) &&
                        <ElevatedCard>
                            <Stack space="xl">
                                <Alert
                                    variant="negative"
                                    heading="An error has occurred"
                                    content={
                                        (errors.generic ||
                                        errors.inputEndAge) ||
                                        "Errors occurred during processing"}
                                />
                            </Stack>
                        </ElevatedCard>
                    }

                    <Box mt={8}>
                        <Stack space="md">
                            <Text as="p">
                               Your 401(k) account balance and any subsequent distributions will be influenced by market performance, your chosen withdrawal age, and the length of time you wish to continue withdrawals.
                            </Text>
                            <Text as="p">
                               You will see the estimated monthly income amount update based on the inputs, but feel free to enter your own amount if you wish. Please note: adjusting your life expectancy to be shorter than the default age increases the probability that you may outlive your End Age and possibly exhausting your retirement plan assets income while living. Conversely, extending your life expectancy beyond the default End Age, may result in income planning for a standard of living less than you could have afforded.
                            </Text>
                            <Box>
                                <ModalIncomeCustomize1 />
                            </Box>
                        </Stack>
                    </Box>

                    <Box mt={8}>
                        <NumberInput
                            label="Start age"
                            description="By default, we've aligned it with your retirement age"
                            value={inputStartAge}
                            isRequired={true}
                            onChange={inputStartAge => handleUpdateStartAge(inputStartAge)}
                            min={55}
                            max={100}
                            step={1}
                        />
                    </Box>

                    <Box mt={8}>
                        <NumberInput
                            label="End age"
                            description="By default, we've used your life expectancy adjusted for longevity risk."
                            value={inputEndAge}
                            isRequired={true}
                            onChange={inputEndAge => handleUpdateEndAge(inputEndAge)}
                            min={55}
                            max={100}
                            step={1}
                            errors={ errors.inputEndAge ? [errors.inputEndAge] : []}
                        />
                    </Box>

                    <Box mt={8} mb={8} display="flex" justifyContent="start">
                        {!(incomeDistResponseInit.isLoading || incomeDistResponseInit.isFetching) ? (
                            <CurrencyInput
                                label="Monthly 401(k) income amount"
                                description="The default is dictated by the ages you selected above"
                                value={
                                    fixNaN(
                                        (typeof inputMonthly === 'number' ?
                                            inputMonthly.toLocaleString() :
                                            inputMonthly))
                                }
                                isRequired={true}
                                onChange={inputMonthly => handleUpdateMonthly(Number.parseInt(inputMonthly || 0))}
                                step={100}
                                currencyLabel="$"
                                characters={12}
                            />
                        ) : (
                            <Box display="flex" justifyContent="start">
                                <PageLoading />
                            </Box>
                        )
                        }
                    </Box>

                    <Adjacent space="md">
                        <Button onClick={handleBackClick} variant="secondaryButton">
                            Back
                        </Button>
                        <Button onClick={handleContinueClick} isLoading={incomeDistResponse.isLoading}>
                            Continue
                        </Button>
                    </Adjacent>

                    <Box mt={8} mb={4}>
                        <QuitAndRestartModal
                            quitAndRestartLinkText="Quit and return to the dashboard"
                            quitAndRestartModalTitle="Are you sure you want to quit?"
                            quitAndRestartModalDescription="If you quit, all your unsaved information will be lost."
                            quitAndRestartModalCancelButtonText="Cancel"
                            quitAndRestartModalConfirmButtonText="Yes, quit"
                            onQuitAndRestartConfirm={restart}
                            isOpen={isOpen}
                            onClose={toggleModal}
                            onOpen={toggleModal}
                        />
                    </Box>
                </Flex>
            </Block>
        </PageWrapper>
    )
}

function fixNaN(val)
{
    val = +Number.parseInt(val?.replace(/,/g, '')) || 0; // Remove commas in currency
    return val.toLocaleString();
}

export default IncomeCustomize1
import React, { useEffect, useState } from 'react'
import { Alert, CurrencyInput, ElevatedCard, PageLoading, NumberInput, PageHeadingBlock, QuitAndRestartModal, Radio, RadioConditional, RadioGroup, Stack, Block, Text } from '@stadion/bright'
import { Box, Flex } from '@chakra-ui/layout'
import { useDispatch, useSelector } from 'react-redux'
import { ModalIncomeCustomizeSocsec } from '../../../utils/modals'
import PageWrapper from '../../PageWrapper'
import IncomeStickyFooter from './IncomeStickyFooter'
import { toggle } from '../../../store/slices/quitModal'
import { useGetIncomeDistMutation } from '../../../store/api'
import { setCustomSocsecStartAge, setCustomMonthlySocsec, setIncludeSocsec } from '../../../store/store'
import { useNavigate, useLocation } from 'react-router-dom'
import { dashboard, incomePlanSummary, incomeCustomizePension, incomeCustomizeConfirm } from '../../../routes'




function IncomeCustomizeSocsec()
{
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const toggleModal = () => dispatch(toggle())
    const { isOpen } = useSelector(state => state.quitModal)
    const customStartAge = useSelector(state => state.incomePlan.customStartAge)
    const customSocsecStartAge = useSelector(state => state.incomePlan.customSocsecStartAge)
    const endAge = useSelector(state => state.incomePlan.endAge)
    const customEndAge = useSelector(state => state.incomePlan.customEndAge)
    const customMonthlyIncome = useSelector(state => state.incomePlan.customMonthlyIncome)
    const customMonthlySocsec = useSelector(state => state.incomePlan.customMonthlySocsec)
    const includeSocsec = useSelector(state => state.incomeAccount.includeSocsec)
    const startAge = useSelector(state => state.incomePlan.startAge)
    const monthlyIncome = useSelector(state => state.incomePlan.monthlyIncome)
    const customPensionStartAge = useSelector(state => state.incomePlan.customPensionStartAge)
    const customMonthlyPension = useSelector(state => state.incomePlan.customMonthlyPension)
    const includePension = useSelector(state => state.incomeAccount.includePension)
    const acceptedPlan = useSelector(state => state.incomePlan.acceptedPlan)
    const [factorSocsec, setFactorSocsec] = useState(includeSocsec === true ? 'yes' : 'no')
    const [ socSecStartAge, setSocSecStartAge] = useState(customSocsecStartAge)
    const [ monthlySocSec, setMonthlySocSec] = useState(customMonthlySocsec)
    const [ getIncomeDist, incomeDistResponse ] = useGetIncomeDistMutation()
    
    
    const [errors, setErrors] = useState({})

    const urlFrom = location.state?.from
    let backHref = incomeCustomizeConfirm
    if(urlFrom !== undefined) backHref = urlFrom
    const editAll = location.state?.editAll;
    
    const restart = () =>
    {
        toggleModal()
        navigate(dashboard)
    }

    const handleBackClick = () =>
    {
        navigate(backHref, {state: { editAll: editAll }})
    }
    const handleContinueClick = () =>
    {
        if(factorSocsec === 'yes') {
            dispatch(setCustomMonthlySocsec(Number.parseInt(monthlySocSec)))
            dispatch(setCustomSocsecStartAge(Number.parseInt(socSecStartAge)))
            dispatch(setIncludeSocsec(true));
        }
        else {
            dispatch(setCustomMonthlySocsec(0))
            dispatch(setCustomSocsecStartAge(0)) 
            dispatch(setIncludeSocsec(false));
        }
        if((urlFrom !== undefined) && (urlFrom.toLowerCase() === incomePlanSummary.toLowerCase()))
           navigate(incomePlanSummary)
        else
           navigate(incomeCustomizePension, {state: { from: location.pathname, editAll: editAll }})
    }

    const handleChangeSocsec = (value) =>
    {
        setFactorSocsec(value)
        const incSoc = value === 'yes';
        dispatch(setIncludeSocsec(incSoc));
    }
    
    useEffect(() => {
        let monthlyDiff = monthlyIncome - customMonthlyIncome;
        let incomeOverride = 0;
        if(monthlyDiff !== 0) incomeOverride = customMonthlyIncome;

        getIncomeDist({
            incomeStartAge: acceptedPlan > 0 ? customStartAge : startAge,
            incomeEndAge: acceptedPlan > 0 ? customEndAge : endAge,
            socSecStartAge: socSecStartAge
        })
    }, [socSecStartAge])
    
    useEffect(() => {
        if (socSecStartAge === 0)
        {
            setSocSecStartAge(startAge)
        }
        
        let monthlyDiff = monthlyIncome - customMonthlyIncome;
        let incomeOverride = 0;
        if(monthlyDiff !== 0) incomeOverride = customMonthlyIncome;
        getIncomeDist({
            //smileChartMode: acceptedPlan > 0 ? customGraphSmileMode : 1,
            incomeStartAge: acceptedPlan > 0 ? customStartAge : startAge,
            incomeEndAge: acceptedPlan > 0 ? customEndAge : endAge,
            //incomeOverride: acceptedPlan > 0 ? incomeOverride : 0,
            socSecOverride: acceptedPlan > 0 ? customMonthlySocsec : 0,
            socSecStartAge: acceptedPlan > 0 ? customSocsecStartAge : 0
        })
    }, [])
    
    useEffect(() => {
        const idr = incomeDistResponse.data?.incomeDistributionList?.find(cd => (cd.age === parseInt(socSecStartAge)))
        if((idr !== undefined) && (idr.socsec > 0)) {
           setMonthlySocSec(Number.parseInt(idr?.socsec))
        }
        // else {
        //     console.log("Error: missing incomeDistributionList data for age: ", socSecStartAge)
        // }
        
    }, [incomeDistResponse])
    
    let incomeStartAge = acceptedPlan > 0 ? customStartAge : startAge
    if(incomeStartAge === 0) incomeStartAge = startAge
    let incomeMonthly = acceptedPlan > 0 ? customMonthlyIncome : monthlyIncome
    if (incomeMonthly === 0) incomeMonthly = monthlyIncome
    
    const footer =
        <IncomeStickyFooter
            backHandler={handleBackClick}
            // backHref={backHref}
            backLabel={'Back'}
            continueHandler={handleContinueClick}
            continueLabel={'Continue'}
            income401k={incomeMonthly}
            start401k={incomeStartAge}
            includeSocsec={includeSocsec}
            incomeSocsec={monthlySocSec}
            startSocsec={socSecStartAge}
            includePension={includePension}
            incomePension={customMonthlyPension}
            startPension={customPensionStartAge}
        />


    const errorEntries = Object.values(errors).filter(err => err)


    return (
        <PageWrapper footer={footer} pageTitle="Income Customize Social Security">
            <Block palette="baseTwo" paddingY="4xl">
                <Flex height="100%" flexDir="column">
                    <PageHeadingBlock
                        pageHeading="Tell us when you plan on starting your Social Security benefits"
                        subheading="Calculate how different adjustments to social security planning could impact your monthly retirement income."
                        showDivider={true}
                    />

                    {
                        (errorEntries.length > 0) &&
                        <ElevatedCard>
                            <Stack space="xl">
                                <Alert
                                    variant="negative"
                                    heading="An error has occurred"
                                    content={
                                        errors.generic ||
                                        "Errors occurred during processing"}
                                />
                            </Stack>
                        </ElevatedCard>
                    }

                    <Box mt={8}>
                        <Stack space="md">
                            <Text as="p">
                                We have estimated your Social Security payment based on the information you have already provided. Visit the <a href="https://www.ssa.gov" style={{color:"#0066CC"}} target="_blank">Social Security Administration</a> website to see your updated monthly benefit amount, which could be more accurate than our estimates.
                            </Text>
                            <ModalIncomeCustomizeSocsec />
                        </Stack>
                    </Box>


                    <Box mt={5}>
                        <RadioGroup
                            label="Do you want to consider your estimated Social Security income in your strategy?"
                           // description="Stadion can only offer educational information about Social Security as this is outside of your 401(k) plan."
                            isRequired={true}
                            onChange={factorSocsec => handleChangeSocsec(factorSocsec)}
                            value={factorSocsec}

                        >
                            <RadioConditional value="yes" label="Yes" description="Factor in my Social Security for education">
                                <Box display="flex" justifyContent="start">
                                    {!(incomeDistResponse.isLoading || incomeDistResponse.isFetching) ? (
                                        <NumberInput
                                            label="Start age"
                                            description="Benefits can start anywhere between 62 and 70 years old"
                                            value={socSecStartAge}
                                            isRequired={true}
                                            onChange={socSecStartAge => setSocSecStartAge(socSecStartAge)}
                                            min={62}
                                            max={70}
                                            step={1}
                                        />
                                    ) : (
                                        <Box display="flex" justifyContent="start">
                                            <PageLoading />
                                        </Box>
                                    )
                                    }
                                </Box>
                                <Box mt={5}>
                                    <CurrencyInput
                                        label="Social Security monthly benefit"
                                        description="You can update this estimate with information from your Social Security statement.<br>Social Security benefits are not tied to any portfolio allocations or estimated 401(k) income amounts that Stadion provides. Users may view Social Security benefits as a component of their overall expected retirement income, presented as a sum of the estimated Social Security benefit and the Estimated Monthly Income Amount from their retirement plan account. The estimated social security benefits are calculated utilizing the 35 years in which you earned the most, based on your current salary and the salary increase projections detailed above. If you are less than 35 years from retirement when the calculation is completed, the algorithm takes the current salary provided and reduces it by 1.5% annually until 35 years of salary data are available. This data is utilized to calculate an average monthly income (Social Security Monthly Income or “SSMI”) across the 35 year period, which is then applied using  current methodology the Social Security Administration follows to determine your monthly social security benefit in retirement. The social security benefit estimate is intended only as an approximation of the actual calculations utilized by the Social Security Administration, which may change from time to time.  Alternatively, users may choose to manually input their own estimate of the Social Security benefit amount to populate a personalized retirement income planning report. Default estimated or user-supplied Social Security amounts are assumed to be held constant throughout the retirement period and do not include any assumption of future “cost of living adjustments.”"
                                        value={
                                            fixNaN(
                                                (typeof monthlySocSec === 'number' ?
                                                    monthlySocSec.toLocaleString() :
                                                    monthlySocSec))
                                        }
                                        isRequired={true}
                                        onChange={monthlySocSec => setMonthlySocSec(Number.parseInt(monthlySocSec || 0))}
                                        step={100}
                                        currencyLabel="$"
                                        characters={12}
                                    />
                                </Box>
                            </RadioConditional>
                            <Radio value="no" label="No" description="Continue without showing how Social Security will impact my  estimated monthly income." />
                        </RadioGroup>
                    </Box>

                    <Box mt={8} mb={4}>
                        <QuitAndRestartModal
                            quitAndRestartLinkText="Quit and return to the dashboard"
                            quitAndRestartModalTitle="Are you sure you want to quit?"
                            quitAndRestartModalDescription="If you quit, all your unsaved information will be lost."
                            quitAndRestartModalCancelButtonText="Cancel"
                            quitAndRestartModalConfirmButtonText="Yes, quit"
                            onQuitAndRestartConfirm={restart}
                            isOpen={isOpen}
                            onClose={toggleModal}
                            onOpen={toggleModal}
                        />
                    </Box>
                </Flex>
            </Block>
        </PageWrapper>
    )
}

function fixNaN(val)
{
    val = +Number.parseInt(val?.replace(/,/g, '')) || 0; // Remove commas in currency
    return val.toLocaleString();
}

export default IncomeCustomizeSocsec
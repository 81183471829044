import React, { useEffect, useState } from 'react'
import { Alert, ElevatedCard, OutlineCard, PageHeadingBlock, PageLoading, QuitAndRestartModal, Radio, RadioGroup, Stack, Block, Button, MainWrapper, Link, Adjacent, Text, useNavigation } from '@stadion/bright'
import { Box, Flex, Grid } from '@chakra-ui/layout'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { ModalDashboardTabOverview1, ModalDashboardTabOverview2, ModalDashboardTabIncome2 } from '../../../utils/modals'
import PageWrapper from '../../PageWrapper'
import { toggle } from '../../../store/slices/quitModal'
import { useGetIncomeDistMutation } from '../../../store/api'
import { setCustomSocsecStartAge, setCustomMonthlySocsec, setIncludeSocsec, setCustomPensionStartAge, setCustomMonthlyPension, setIncludePension } from '../../../store/store'
import StackedBarChartIncome from '../../charts/StackedBarChartIncome'
import useUser from '../../../store/useUser'
import { dashboard, incomePlanSummary, incomeReview, incomeCustomizeSocsec, incomeComparePlans } from '../../../routes'


function IncomeCustomizeConfirm() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const toggleModal = () => dispatch(toggle())
    const { isOpen } = useSelector(state => state.quitModal)
    const [ errors, setErrors ] = useState({})
    const [ create401k, setCreate401k ] = useState(undefined)
    const startAge = useSelector(state => state.incomePlan.startAge)
    const endAge = useSelector(state => state.incomePlan.endAge)
    const monthlyIncome = useSelector(state => state.incomePlan.monthlyIncome)
    const customStartAge = useSelector(state => state.incomePlan.customStartAge)
    const customEndAge = useSelector(state => state.incomePlan.customEndAge)
    const customMonthlyIncome = useSelector(state => state.incomePlan.customMonthlyIncome)
    const customGraphSmileMode = useSelector(state => state.incomePlan.customGraphSmileMode)
    const acceptedPlan = useSelector(state => state.incomePlan.acceptedPlan)
    const [ getIncomeDist, incomeDistResponse ] = useGetIncomeDistMutation()

    const user = useUser()
    
    const editAll = location.state?.editAll;
   

    
    useEffect(() => {
        // console.log("Accepted plan: " + acceptedPlan + " CustomGraphSmileMode: " + customGraphSmileMode)
        // console.log("CustomMonthlyIncome " + customMonthlyIncome)
        // console.log("getIncomeDist", customMonthlyIncome, monthlyIncome)
        // console.log("smileChartMode", acceptedPlan > 0 ? customGraphSmileMode : 0)
        // console.log("incomeStartAge", acceptedPlan > 0 ? customStartAge : startAge)
        // console.log("incomeEndAge", acceptedPlan > 0 ? customEndAge : endAge)

        getIncomeDist({
            smileChartMode: acceptedPlan > 0 ? customGraphSmileMode : 1,
            incomeStartAge: acceptedPlan > 0 ? customStartAge : startAge,
            incomeEndAge: acceptedPlan > 0 ? customEndAge : endAge,
            incomeOverride: acceptedPlan > 0 ? customMonthlyIncome : 0,
        })
    }, [])
            
    const restart = () =>
    {
        toggleModal()
        navigate(dashboard)
    } 
               
    const handleContinueClick = () =>
    {
        if(create401k === 'no') {
            dispatch(setIncludeSocsec(false));
            dispatch(setCustomSocsecStartAge(0));
            dispatch(setCustomMonthlySocsec(0));
            dispatch(setIncludePension(false));
            dispatch(setCustomPensionStartAge(0));
            dispatch(setCustomMonthlyPension(0));
            if((editAll !== undefined) && (editAll.toLowerCase() === incomePlanSummary.toLowerCase(0)))
                navigate(incomePlanSummary)
            else
                navigate(incomeReview)
        }
        else if(create401k === 'yes') {
           navigate(incomeCustomizeSocsec, {state: { editAll: editAll }})

        }
    }
    
    const errorEntries = Object.values(errors).filter(err => err)
    
    // const incomeList = incomeDistResponse?.data?.incomeDistributionList; 
    const incomeList = incomeDistResponse?.data?.incomeDistributionList.filter(el => el.income > 0)
    const retirementBalance = incomeDistResponse?.data?.incomeAcctValueAtRetire;
    let monthlyRetireIncome = incomeDistResponse?.data?.incomeMonthlyAtRetire;
    const retirementAmts = incomeList?.find(cd => (cd.age === parseInt(customStartAge))) 
    if(retirementAmts !== undefined) {
        monthlyRetireIncome = retirementAmts.income;
    }
    const include401k = true;
    const includeSocSec = false;
    const includePension = false;
    
   
    return (
        <PageWrapper pageTitle="Income Customize and Confirm">
            <Block palette="baseTwo" paddingY="4xl">
                <Flex height="100%" flexDir="column">
                    <PageHeadingBlock
                        pageHeading={"Confirm and implement your income estimate or continue to customize"}
                        showDivider={true}
                    />

                    {
                        errorEntries.length > 0 &&
                        <ElevatedCard>
                            <Stack space="xl">
                                <Alert
                                    variant="negative"
                                    heading="A system error has occurred"
                                    content={
                                        errors.generic ||
                                        "Errors occurred during processing"}
                                />
                            </Stack>
                        </ElevatedCard>
                    }

                    <Box mt={8}>
                        <Text as="p">
                           Now that you have selected to customize your personal income strategy, you can include your Social Security and pension, when applicable.                        
                        </Text>
                    </Box>
                    <Box mt={3}>
                        <Text as="p">
                           Social Security and pension amounts are participant inputs and Stadion only provides education on them.
                        </Text>
                    </Box>

                    <OutlineCard mt={8}>
                        <Stack space="md">
                            {!(incomeDistResponse.isLoading || incomeDistResponse.isFetching) ? (
                                <div>
                                    <Box mt={5}>
                                        <StackedBarChartIncome
                                            barChartData={incomeList}
                                            include401k={include401k}
                                            includeSocSec={includeSocSec}
                                            includePension={includePension}
                                        />
                                    </Box>
                                    <Box mt={5}>
                                        <Grid
                                            templateColumns={
                                                [
                                                    'auto',
                                                    '1fr 1px 1fr 1px 1fr'
                                                ]
                                            }
                                            columnGap="4"
                                            rowGap="4"
                                            minWidth={'600px'}
                                        >
                                            <Stack space="sm">
                                                <Box
                                                    h="50px"
                                                    sx={{
                                                        "@media screen and (max-width: 600px)": {
                                                            height: 15,
                                                        },
                                                    }}
                                                >
                                                    <Text level="sm" as="b" variant="primary">
                                                        Estimated 401(k) balance at retirement:
                                                    </Text>
                                                </Box>
                                                <Box>
                                                    <Text level="xl" as="b" variant="primary">
                                                        ${Math.round(retirementBalance).toLocaleString()} 
                                                    </Text>
                                                    <ModalDashboardTabOverview1 />
                                                </Box>
                                            </Stack>
                                            <Border />
                                            <Stack space="sm">
                                                <Box
                                                    h="50px"
                                                    sx={{
                                                        "@media screen and (max-width: 600px)": {
                                                            height: 15,
                                                        },
                                                    }}
                                                >
                                                    <Text level="sm" as="b" variant="primary">
                                                        Est 401(k) monthly income at retirement:
                                                    </Text>
                                                </Box>
                                                <Box>
                                                    <Text level="xl" as="b" variant="primary">
                                                        ${Math.round(monthlyRetireIncome).toLocaleString()} 
                                                    </Text>
                                                    <ModalDashboardTabOverview2 />
                                                </Box>
                                            </Stack>
                                            <Border />
                                            <Stack space="sm">
                                                <Box
                                                    h="50px"
                                                    sx={{
                                                        "@media screen and (max-width: 600px)": {
                                                            height: 15,
                                                        },
                                                    }}
                                                >
                                                    <Text level="sm" as="b" variant="primary">
                                                        Duration of income:
                                                    </Text>
                                                </Box>
                                                <Box>
                                                    <Text level="xl" as="b" variant="primary">
                                                        {incomeDistResponse ? incomeDistResponse.data?.incomeStartAge + "-" + incomeDistResponse.data?.incomeEndAge : "0"} 
                                                    </Text>
                                                    <ModalDashboardTabIncome2 />
                                                </Box>
                                            </Stack>
                                        </Grid>
                                    </Box>
                                </div>
                            ) : (
                                <PageLoading />
                            )
                            }
                        </Stack>
                    </OutlineCard>

                    <Box mt={8} mb={8}>
                        <RadioGroup
                            label="Do you want to confirm your 401(k) monthly income estimate or would you like to see how your Social Security and pension can impact your income in retirement?"
                            description="Stadion can only offer educational information about your pension and social security as it is outside of your 401(k) plan."
                            isRequired={true}
                            onChange={value => setCreate401k(value)}
                            value={create401k}
                        >
                            <Radio value="yes" label="Yes" description="I would like to see how those impact my estimated monthly income estimate." />
                            <Radio value="no" label="No" description="Use my 401(k) savings for my estimated income." />
                        </RadioGroup>
                    </Box>

                    <Adjacent space="md">
                        <Link href={incomeComparePlans} variant="secondaryButton">
                            Back
                        </Link>
                        <Button onClick={handleContinueClick}>
                            Continue
                        </Button>
                    </Adjacent>

                    <Box mt={8} mb={4}>
                        <QuitAndRestartModal
                            quitAndRestartLinkText="Quit and return to the dashboard"
                            quitAndRestartModalTitle="Are you sure you want to quit?"
                            quitAndRestartModalDescription="If you quit, all your unsaved information will be lost."
                            quitAndRestartModalCancelButtonText="Cancel"
                            quitAndRestartModalConfirmButtonText="Yes, quit"
                            onQuitAndRestartConfirm={restart}
                            isOpen={isOpen}
                            onClose={toggleModal}
                            onOpen={toggleModal}
                        />
                    </Box>
                </Flex>
            </Block>
        </PageWrapper>
    )
}

function Border() {
    return <div style={ { backgroundColor: '#E0E0E0', minHeight: '1px' } }></div>
}

export default IncomeCustomizeConfirm
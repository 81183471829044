import React, { useEffect, useState } from 'react'
import { Alert, ElevatedCard, OutlineCard, PageHeadingBlock, PageLoading, QuitAndRestartModal, Radio, RadioGroup, Stack, Block, Button, Link, Adjacent, Text, useNavigation } from '@stadion/bright'
import { Box, Flex, Grid } from '@chakra-ui/layout'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { ModalDashboardTabOverview1, ModalDashboardTabOverview2, ModalDashboardTabIncome2 } from '../../../utils/modals'
import PageWrapper from '../../PageWrapper'
import { toggle } from '../../../store/slices/quitModal'
import { setAcceptedPlan, setCustomStartAge, setCustomEndAge, setCustomMonthlyIncome, setCustomSocsecStartAge, setCustomMonthlySocsec, setIncludeSocsec, setCustomPensionStartAge, setCustomMonthlyPension, setIncludePension } from '../../../store/store'
import { useGetIncomeDistMutation } from '../../../store/api'
import StackedBarChartIncome from '../../charts/StackedBarChartIncome'
import useUser from '../../../store/useUser'
import { dashboard, incomePlanSummary, incomeReview, incomeCustomize1, incomeWelcome } from '../../../routes'


function IncomeStep1() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();
    const toggleModal = () => dispatch(toggle())
    const { isOpen } = useSelector(state => state.quitModal)
    const [ errors, setErrors ] = useState({})
    const [ create401k, setCreate401k ] = useState(undefined)
    const acceptedPlan = useSelector(state => state.incomePlan.acceptedPlan)
    const [ getIncomeDist, incomeDistResponse ] = useGetIncomeDistMutation()

    const user = useUser()
    
    const editAll = location.state?.editAll;
       
    
    useEffect(() => {
        getIncomeDist({
            smileChartMode:  1,
            //incomeStartAge: startAge,
            //incomeEndAge: endAge
        })
    }, [acceptedPlan])
    
            
    const restart = () =>
    {
        toggleModal()
        navigate(dashboard)
    } 
               
    const handleContinueClick = () =>
    {
        if(create401k === 'yes') {
            // Reset any custom income entries
            dispatch(setCustomStartAge(0))
            dispatch(setCustomEndAge(0))
            dispatch(setCustomMonthlyIncome(0))
            dispatch(setIncludeSocsec(false));
            dispatch(setCustomSocsecStartAge(0));
            dispatch(setCustomMonthlySocsec(0));
            dispatch(setIncludePension(false));
            dispatch(setCustomPensionStartAge(0));
            dispatch(setCustomMonthlyPension(0));
            dispatch(setAcceptedPlan(0))            // Use default plan
            if((editAll !== undefined) && (editAll.toLowerCase() === incomePlanSummary.toLowerCase()))
                navigate(incomePlanSummary)
            else
                navigate(incomeReview)
        }
        else if(create401k === 'no') {
           navigate(incomeCustomize1, {state: { editAll: editAll }})
        }   
    }
    
    const errorEntries = Object.values(errors).filter(err => err)
    
    const incomeList = incomeDistResponse?.data?.incomeDistributionList; 
    const retirementBalance = incomeDistResponse?.data?.incomeAcctValueAtRetire;
    const monthlyIncome = incomeDistResponse?.data?.incomeMonthlyAtRetire;
    const include401k = true;
    const includeSocSec = false;
    const includePension = false;
    const monthlyPension = 0;
    
    
    return (
        <PageWrapper pageTitle="Income Step 1">
            <Block palette="baseTwo" paddingY="4xl">
                <Flex height="100%" flexDir="column">
                    <PageHeadingBlock
                        pageHeading={"Your Stadion 401(k) income estimate"}
                        showDivider={true}
                    />

                    {
                        errorEntries.length > 0 &&
                        <ElevatedCard>
                            <Stack space="xl">
                                <Alert
                                    variant="negative"
                                    heading="A system error has occurred"
                                    content={
                                        errors.generic ||
                                        "Errors occurred during processing"}
                                />
                            </Stack>
                        </ElevatedCard>
                    }


                    <OutlineCard mt={8}>
                        <Stack space="md">
                            {!(incomeDistResponse.isLoading || incomeDistResponse.isFetching) ? (
                                <div>
                                    <Text as="p">
                                        Based on the information you provided when you retire at age {incomeDistResponse?.data?.incomeStartAge}, and on the assumptions used for our projections, you may have a retirement savings balance of ${Math.round(retirementBalance).toLocaleString()} and generate an estimated monthly income of ${Math.round(monthlyIncome).toLocaleString()}
                                    </Text>
                                    <Box mt={5}>
                                        <Text as="p">
                                            The graph below shows income decreasing in the middle, which we refer to as the retirement smile. We anticipate your retirement expenses will reduce over time, causing a slowdown in spending. However, spending will gradually increase in your later years as healthcare costs can offset the reduction in spending. We have factored this spending habit into our income estimate for you.
                                        </Text>
                                    </Box>
                                    <Box mt={5}>
                                        <StackedBarChartIncome
                                            barChartData={incomeList}
                                            include401k={include401k}
                                            includeSocSec={includeSocSec}
                                            includePension={includePension}
                                            monthlyPension={monthlyPension}
                                        />
                                    </Box>
                                    <Box mt={5}>
                                        <Grid
                                            templateColumns={
                                                [
                                                    'auto',
                                                    '1fr 1px 1fr 1px 1fr'
                                                ]
                                            }
                                            columnGap="4"
                                            rowGap="4"
                                            minWidth={'600px'}
                                        >
                                            <Stack space="sm">
                                                <Box
                                                    h="50px"
                                                    sx={{
                                                        "@media screen and (max-width: 600px)": {
                                                            height: 15,
                                                        },
                                                    }}
                                                >
                                                    <Text level="sm" as="b" variant="primary">
                                                        Estimated 401(k) balance at retirement:
                                                    </Text>
                                                </Box>
                                                <Box>
                                                    <Text level="xl" as="b" variant="primary">
                                                        ${Math.round(retirementBalance).toLocaleString()}
                                                    </Text>
                                                    <ModalDashboardTabOverview1 />
                                                </Box>
                                            </Stack>
                                            <Border />
                                            <Stack space="sm">
                                                <Box
                                                    h="50px"
                                                    sx={{
                                                        "@media screen and (max-width: 600px)": {
                                                            height: 15,
                                                        },
                                                    }}
                                                >
                                                    <Text level="sm" as="b" variant="primary">
                                                        Est 401(k) monthly income at retirement:
                                                    </Text>
                                                </Box>
                                                <Box>
                                                    <Text level="xl" as="b" variant="primary">
                                                        ${Math.round(monthlyIncome).toLocaleString()} 
                                                    </Text>
                                                    <ModalDashboardTabOverview2 />
                                                </Box>
                                            </Stack>
                                            <Border />
                                            <Stack space="sm">
                                                <Box
                                                    h="50px"
                                                    sx={{
                                                        "@media screen and (max-width: 600px)": {
                                                            height: 15,
                                                        },
                                                    }}
                                                >
                                                    <Text level="sm" as="b" variant="primary">
                                                        Duration of income:
                                                    </Text>
                                                </Box>
                                                <Box>
                                                    <Text level="xl" as="b" variant="primary">
                                                        {incomeDistResponse ? incomeDistResponse.data?.incomeStartAge + "-" + incomeDistResponse.data?.incomeEndAge : "0"} 
                                                    </Text>
                                                    <ModalDashboardTabIncome2 />
                                                </Box>
                                            </Stack>
                                        </Grid>
                                    </Box>
                                </div>
                            ) : (
                                <PageLoading />
                            )
                            }
                        </Stack>
                    </OutlineCard>

                    <Box mt={8} mb={8}>
                        <RadioGroup
                            label="Do you want to keep the Stadion estimated income amount?"
                            isRequired={true}
                            onChange={value => setCreate401k(value)}
                            value={create401k}
                        >
                            <Radio value="yes" label="Yes" description="Keep the Stadion estimated amount" />
                            <Radio value="no" label="No" description="Add in additional information to see how adjusting inputs may impact the estimate of the monthly income your retirement plan could generate." />
                        </RadioGroup>
                    </Box>

                    <Adjacent space="md">
                        <Link href={incomeWelcome} variant="secondaryButton">
                            Back
                        </Link>
                        <Button onClick={handleContinueClick}>
                            Continue
                        </Button>
                    </Adjacent>

                    <Box mt={8} mb={4}>
                        <QuitAndRestartModal
                            quitAndRestartLinkText="Quit and return to the dashboard"
                            quitAndRestartModalTitle="Are you sure you want to quit?"
                            quitAndRestartModalDescription="If you quit, all your unsaved information will be lost."
                            quitAndRestartModalCancelButtonText="Cancel"
                            quitAndRestartModalConfirmButtonText="Yes, quit"
                            onQuitAndRestartConfirm={restart}
                            isOpen={isOpen}
                            onClose={toggleModal}
                            onOpen={toggleModal}
                        />
                    </Box>
                </Flex>
            </Block>
        </PageWrapper>
    )
}

function Border() {
    return <div style={ { backgroundColor: '#E0E0E0', minHeight: '1px' } }></div>
}

export default IncomeStep1